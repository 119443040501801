/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastService } from './toast.service';
import { AuthService } from '../features/auth/auth.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class FmErrorHandlerService extends ErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }
  override async handleError(error: any) {
    if (this.isErrorHandled(error)) {
      console.log('[ERROR handled!] ', error);
      return;
    }

    if (this.handleAuthRelatedErrors(error)) {
      // do not log auth errors since these are caught on the backend also
      return;
    }

    if (this.handleChunkErrors(error)) {
      // do not log chunk errors
      return;
    }

    const messagePrefix = 'Something went wrong!';
    const message = await this.parseError(error);
    this.zone.run(async () => {
      await this.toastService.showToast({
        message: message ? `${messagePrefix} \n\r${message}` : messagePrefix,
      });
    });

    super.handleError(error);

    if (error instanceof HttpErrorResponse) {
      const httpError = new Error();

      httpError.message = error?.error?.message || error?.message || error;
      httpError.name = error?.error?.name || error?.name || error;
      httpError.stack = error?.error?.stack;

      Sentry.captureException(httpError, (scope) => {
        scope.setExtra('rawOriginalError', JSON.stringify(error));
        scope.setExtra('rawSyntheticError', JSON.stringify(httpError));
        scope.setTransactionName(`${httpError.name}: ${httpError.message}`);

        return scope;
      });
    } else {
      Sentry.captureException(error.error || error);
    }
  }

  handleHttpInterceptorError(error: any) {
    this.handleForbidden(error);

    this.handleNoHttpResponse(error);

    // Do not trigger UI error from interceptor
    // If we have an http call where we want to catch the error inside the service and handle it there
    // We don't want in this case that the global interceptor to show an error
    // eg: service.getId().pipe(catchError() => customLogic)
    // Unhandled errors will enter in the custom error handler and show the errors
    // We are gonna still keep the interceptor for a while for custom generic errors on API calls

    // this.handleError(error);
  }

  private isErrorHandled(error: any) {
    if (!error) {
      // do not log empty error
      return true;
    }
    return (
      error?.status === 0 ||
      error?.status === 403 ||
      error?.currentTarget?.status === 0 ||
      error?.currentTarget?.status === 403 ||
      ((error?.status === 404 || error?.currentTarget?.status === 404) &&
        error?.error?.message === 'Application not found')
    );
  }

  private handleNoHttpResponse(error: any) {
    if (error?.status === 0 || error?.currentTarget?.status === 0) {
      this.zone.run(async () => {
        await this.toastService.showToast({
          message:
            'Could not connect to the server! Please check internet connection and reload the application!',
        });
      });
    }
  }

  private handleChunkErrors(error: { name?: string; message?: string }) {
    if (
      error &&
      !(error.name === 'ChunkLoadError' || (error.message?.indexOf('ChunkLoadError') ?? -1) >= 0)
    ) {
      return false;
    }

    this.zone.run(async () => {
      const toast = await this.toastService.showToast({
        message:
          'Update Available: The application has been improved! \r\n To ensure you have the latest features and security updates, we will refresh your browser automatically. This will only take a moment.',
      });
      await toast.onDidDismiss();
      window.location.reload();
    });

    return true;
  }

  private handleAuthRelatedErrors(error: any) {
    if (!error?.rejection) {
      return false;
    }
    const invalidRefreshTokenExceptions = [
      'PasswordResetRequiredException',
      'NotAuthorizedException',
    ];
    const { code } = error.rejection;
    if (invalidRefreshTokenExceptions.indexOf(code) >= 0) {
      this.authService.signOut().then(() => window.location.reload());
      return true;
    }
    return false;
  }

  private handleForbidden(error: any) {
    // const isApplicationPageOpen = window.location.pathname.startsWith('/portal/application/');
    const is403StatusCode = error?.status === 403 || error?.currentTarget?.status === 403;
    // const is404StatusCode = error?.status === 404 || error?.currentTarget?.status === 404;
    // const noApplicationPermissions =
    //   isApplicationPageOpen &&
    //   ((is404StatusCode && error?.error?.message === 'Application not found') || is403StatusCode);

    if (is403StatusCode) {
      this.authService.signOut().then(() => window.location.reload());
    }
  }

  private async parseError(error: any) {
    if (!error) {
      return 'Can not determine the error message.';
    }
    if (typeof error === 'string') {
      return error;
    }
    if (error?.error instanceof ErrorEvent) {
      return error.error.message;
    }
    if (error instanceof HttpErrorResponse) {
      return this.getHttpMessage(error);
    }
    if (typeof error === 'object') {
      return this.parseObjectMessage(error);
    }

    return error?.message;
  }

  private blobToText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  private async getHttpMessage(error: HttpErrorResponse) {
    if (typeof error.error?.message === 'string') {
      return error.error.message;
    }
    if (error.error?.message?.isJoi) {
      return error.error.message.details[0]?.message;
    }
    if (error.error?.message?.details[0]?.message) {
      return error.error.message.details[0]?.message;
    }
    if (error.error instanceof Blob) {
      const blobError = error.error;
      if (blobError.type === 'application/json') {
        const json = await this.blobToText(blobError);
        const parsedBlobError = JSON.parse(json);
        if (parsedBlobError && parsedBlobError.message) {
          return parsedBlobError.message;
        }
      }
    }
    return error.statusText;
  }

  private parseObjectMessage(error: any) {
    const firebaseError = this.authService.getFirebaseErrorMessage(error);
    if (firebaseError) {
      return firebaseError;
    }
    if (error.rejection) {
      return error.rejection?.message ?? error.message;
    }
    if (typeof error?.message === 'string') {
      return error.message;
    }
    if (error.message?.isJoi) {
      return error.message.details[0]?.message;
    }
    return error;
  }
}
