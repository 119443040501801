import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Auth } from '@angular/fire/auth';
import { AuthGetUserDetail, AuthRegisterUserDevice } from './auth.action';
import { catchError, combineLatestWith, lastValueFrom, of, tap } from 'rxjs';

export const authGuard: CanActivateFn = async (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);
  const store = inject(Store);

  await auth.authStateReady();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    await router.navigateByUrl('/auth', { replaceUrl: true });
    return false;
  }
  try {
    await lastValueFrom(
      store.dispatch(new AuthGetUserDetail()).pipe(
        combineLatestWith(
          store.dispatch(new AuthRegisterUserDevice()).pipe(
            catchError((err) => {
              console.error(err);
              return of(null);
            }),
          ),
        ),
      ),
    );

    return true;
  } catch (err) {
    await router.navigateByUrl('/auth', { replaceUrl: true });
    throw err;
  }
};
