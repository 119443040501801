import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { from, switchMap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const auth = inject(Auth);
  if (auth.currentUser) {
    return from(auth.currentUser.getIdToken()).pipe(
      switchMap((token) => {
        let headers = req.headers.append('Authorization', token);
        const scopeContext = window.localStorage.getItem('scope');
        if (scopeContext) {
          headers = headers.append('x-scope-context', scopeContext);
        }
        const newRequest = req.clone({
          headers: headers,
        });
        return next(newRequest);
      }),
    );
  }
  return next(req);
};
